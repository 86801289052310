.image-cover {
  min-height: 350px;
  padding: 1em 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  @media (max-width: 769px) {
    background-image: url("../components/img/mainCover/2x.jpg") !important;
  }
  @media (max-width: 960px) and (min-width: 769px) {
    background-image: url("../components/img/mainCover/3x.jpg") !important;
  }
  @media (max-width: 1344px) and (min-width: 960px) {
    background-image: url("../components/img/mainCover/4x.jpg") !important;
  }
  @media (min-width: 1344px) {
    background-image: url("../components/img/mainCover/5x.jpg") !important;
  }
}