body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image-cover {
  min-height: 350px;
  padding: 1em 0;
  background-size: cover !important;
  background-repeat: no-repeat !important; }
  @media (max-width: 769px) {
    .image-cover {
      background-image: url(/static/media/2x.b5603986.jpg) !important; } }
  @media (max-width: 960px) and (min-width: 769px) {
    .image-cover {
      background-image: url(/static/media/3x.36f25fe6.jpg) !important; } }
  @media (max-width: 1344px) and (min-width: 960px) {
    .image-cover {
      background-image: url(/static/media/4x.9f00025b.jpg) !important; } }
  @media (min-width: 1344px) {
    .image-cover {
      background-image: url(/static/media/5x.729ed399.jpg) !important; } }

#board {
  border-collapse: collapse;
}

#winner {
  margin-top: 25px;
  width: 168px;
  text-align: center;
}

td {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: #555;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 3px solid #aaa;
  background: rgb(130, 39, 39);
}

td.active {
  cursor: pointer;
  background: #286815;
}

td.active:hover {
  background: #1f6060;
}

@charset "UTF-8";
#splash {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  background-color: black;
  color: white;
  opacity: 0;
  transition: opacity 0.2s;
  display: none; }
  #splash canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.65; }
  #splash.visible {
    display: inline;
    opacity: 1; }
  #splash #splash-container {
    width: 90%;
    max-width: 800px;
    min-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: "DIN Condensed";
    text-transform: uppercase;
    text-align: center; }
    #splash #splash-container #title {
      font-size: 40px;
      margin-bottom: 0; }
      @media (max-width: 800px) {
        #splash #splash-container #title {
          font-size: 75px; }
          #splash #splash-container #title:before {
            top: 25px !important; } }
      @media (max-height: 420px), (max-width: 500px) {
        #splash #splash-container #title {
          font-size: 63px; }
          #splash #splash-container #title:before {
            top: 20px !important; } }
      @media (max-height: 420px) {
        #splash #splash-container #title {
          margin-top: 0; }
          #splash #splash-container #title:before {
            top: -5px !important; } }
      #splash #splash-container #title:before {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 40px;
        font-size: 0.3em;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); }
    #splash #splash-container #featuring {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      display: none; }
      @media (max-width: 800px) {
        #splash #splash-container #featuring {
          font-size: 16px; } }
      @media (max-width: 500px) {
        #splash #splash-container #featuring {
          font-size: 10px; } }
      @media (max-height: 420px) {
        #splash #splash-container #featuring {
          display: none; } }
    #splash #splash-container #bands {
      margin-top: 15px;
      width: 100%;
      max-width: 500px;
      min-width: 300px;
      font-size: 26px;
      display: inline-block;
      padding: 0; }
      @media (max-width: 800px) {
        #splash #splash-container #bands {
          font-size: 20px; } }
      @media (max-width: 500px) {
        #splash #splash-container #bands {
          margin-top: 5px;
          font-size: 16px; } }
      @media (max-height: 420px) {
        #splash #splash-container #bands {
          display: none; } }
      #splash #splash-container #bands span {
        display: inline-block; }
        #splash #splash-container #bands span:after {
          margin-left: 5px;
          margin-right: 5px;
          display: inline;
          content: "/"; }
        #splash #splash-container #bands span:last-child:after {
          content: ""; }
    #splash #splash-container #remember {
      text-transform: uppercase; }
    #splash #splash-container #enter-vr-container {
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      min-width: 170px;
      min-height: 46px; }
      @media (max-width: 800px) {
        #splash #splash-container #enter-vr-container {
          margin-top: 30px; } }
      @media (max-height: 420px) {
        #splash #splash-container #enter-vr-container {
          margin-top: 10px; } }
      #splash #splash-container #enter-vr-container .webvr-ui-button {
        border-radius: 0;
        border: white 4px solid; }
        #splash #splash-container #enter-vr-container .webvr-ui-button:hover {
          background-color: rgba(136, 136, 136, 0.5); }
        #splash #splash-container #enter-vr-container .webvr-ui-button .mode360 {
          left: 18px; }
          #splash #splash-container #enter-vr-container .webvr-ui-button .mode360 * {
            float: left; }
          #splash #splash-container #enter-vr-container .webvr-ui-button .mode360 img {
            margin-right: 5px;
            height: 25px; }
          #splash #splash-container #enter-vr-container .webvr-ui-button .mode360 span {
            height: 25px;
            line-height: 25px; }
  @media (max-height: 420px), (max-width: 500px) {
    #splash .aboutButton {
      top: 20px !important;
      right: 20px !important; }
    #splash #headphones {
      top: 20px !important;
      left: 20px !important; } }
  #splash #openAbout:before {
    content: "?"; }
  #splash #closeAbout:before {
    font-size: 30px;
    content: "\D7"; }
  #splash .aboutButton {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    text-align: center;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    cursor: pointer;
    line-height: 40px; }
    #splash .aboutButton:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
      #splash .aboutButton:hover:active {
        color: #5dece8; }
    #splash .aboutButton:before {
      font-family: sans-serif;
      font-size: 20px; }
  #splash #headphones {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 15px;
    color: black;
    overflow: hidden;
    transition: width 0.2s; }
    #splash #headphones:hover {
      width: 180px; }
    #splash #headphones img {
      width: auto;
      display: inline-block;
      left: 3px;
      height: 28px;
      position: absolute; }
    #splash #headphones #text {
      width: 140px;
      height: 100%;
      display: inline-block;
      height: 100%;
      position: absolute;
      line-height: 30px;
      left: 32px;
      font-size: 11px; }
  #splash #about {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    z-index: 1;
    background-color: black;
    display: none;
    font-family: Arial, Helvetica, sans-serif; }
    #splash #about.visible {
      display: block; }
    #splash #about #content {
      position: relative;
      width: 70%;
      max-width: 600px;
      min-width: 300px;
      margin: 50px auto 40px; }
      #splash #about #content h2 {
        line-height: 1em;
        font-size: 2.3em;
        text-transform: uppercase;
        font-family: "DIN Condensed", sans-serif;
        margin-top: 40px; }
      #splash #about #content a {
        color: #5dece8; }
      #splash #about #content .howToPlay {
        display: block;
        margin-top: 10px;
        margin-bottom: 15px; }
      #splash #about #content p {
        margin-top: 20px;
        margin-bottom: 20px; }
  #splash #badges {
    position: absolute;
    bottom: 20px;
    left: 20px;
    height: 50px;
    width: 500px; }
    @media (max-width: 800px), (max-height: 420px) {
      #splash #badges {
        bottom: 10px;
        left: 10px;
        height: 40px; } }
    @media (max-height: 420px) {
      #splash #badges {
        height: 35px; } }
    @media (max-width: 500px) {
      #splash #badges {
        bottom: 50px;
        width: 300px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); } }
    #splash #badges .badge {
      width: auto;
      height: 100%;
      display: inline-block; }
    #splash #badges .keyline {
      width: 1px;
      background-color: white;
      height: 100%;
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px; }
      @media (max-width: 800px), (max-height: 420px) {
        #splash #badges .keyline {
          margin-left: 10px;
          margin-right: 10px; } }
    #splash #badges a {
      width: auto;
      height: 100%;
      color: black;
      background: transparent; }
  #splash #terms {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    font-size: 11px;
    width: 81px; }
    @media (max-width: 800px), (max-height: 420px) {
      #splash #terms {
        bottom: 10px;
        right: 10px; } }
    @media (max-width: 500px) {
      #splash #terms {
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0); } }
    #splash #terms a {
      text-decoration: none; }
      #splash #terms a:hover {
        text-decoration: underline; }

#exitButton {
  position: fixed;
  top: 70px;
  left: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 30px;
  color: white;
  display: none;
  cursor: pointer;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAASklEQVR4Ae2WUQEAERTAtFLtfb7IEoAGALAF2O4ABmoBCBp0rj6jc/UZmat3waJHjz6Dvpl+/f7A5MSySSZBggQJufPx2/p8B4AIFY44W50u+/YAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  z-index: 10000000000; }
  #exitButton.visible {
    display: block; }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.modal {
    height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
}

