#board {
  border-collapse: collapse;
}

#winner {
  margin-top: 25px;
  width: 168px;
  text-align: center;
}

td {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: #555;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 3px solid #aaa;
  background: rgb(130, 39, 39);
}

td.active {
  cursor: pointer;
  background: #286815;
}

td.active:hover {
  background: #1f6060;
}
